import React from "react"
import tw, { styled } from "twin.macro"
import anime from "animejs"
import { InView } from "react-intersection-observer"

export default ({ name, color }) => {
  return (
    <InView
      triggerOnce={true}
      onChange={(inView, entry) => {
        if (inView)
          anime({
            targets: `.${name} path`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "easeInOutSine",
            duration: 1500,
            delay: function (el, i) {
              return i * 250
            },
            loop: true,
            direction: "alternate",
          })
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1616.48 218.2"
        class={name}
      >
        <defs>
          <style>
            {`.animated-text{fill:none;stroke:${
              color ? color : "#000"
            };stroke-miterlimit:10;stroke-width:2px;}`}
          </style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              class="animated-text"
              d="M191.6,214.1l-.3-126L129.5,191.9H107.6L46.1,90.8V214.1H.5V4.1H40.7l78.6,130.5L196.7,4.1h39.9l.6,210Z"
            />
            <path
              class="animated-text"
              d="M425.3,169.1H327.8l-18.6,45H259.4L353,4.1h48l93.9,210h-51ZM410,132.2,376.7,51.8l-33.3,80.4Z"
            />
            <path
              class="animated-text"
              d="M650.59,214.1l-40.5-58.5h-44.7v58.5h-48.6V4.1h90.9c56.1,0,91.21,29.1,91.21,76.2,0,31.5-15.9,54.6-43.21,66.3l47.11,67.5ZM605,43.7h-39.6v73.2H605c29.7,0,44.7-13.8,44.7-36.6C649.69,57.2,634.69,43.7,605,43.7Z"
            />
            <path
              class="animated-text"
              d="M871.09,214.1l-40.5-58.5h-44.7v58.5h-48.6V4.1h90.9c56.1,0,91.2,29.1,91.2,76.2,0,31.5-15.9,54.6-43.2,66.3l47.1,67.5ZM825.49,43.7h-39.6v73.2h39.6c29.7,0,44.7-13.8,44.7-36.6C870.19,57.2,855.19,43.7,825.49,43.7Z"
            />
            <path
              class="animated-text"
              d="M944.58,109.1C944.58,46.7,993.19.5,1059.49.5c66,0,114.9,45.9,114.9,108.6s-48.9,108.6-114.9,108.6C993.19,217.7,944.58,171.5,944.58,109.1Zm180.61,0c0-39.9-28.2-67.2-65.7-67.2s-65.71,27.3-65.71,67.2,28.2,67.2,65.71,67.2S1125.19,149,1125.19,109.1Z"
            />
            <path
              class="animated-text"
              d="M1403.58,4.1v210h-39.9L1259,86.6V214.1h-48V4.1h40.2l104.4,127.5V4.1Z"
            />
            <path
              class="animated-text"
              d="M1616,175.1v39h-162.6V4.1h158.7v39h-110.4V88.7h97.5v37.8h-97.5v48.6Z"
            />
          </g>
        </g>
      </svg>
    </InView>
  )
}
