import React from "react"
import tw, { styled, css } from "twin.macro"
import AnimatedLink from "./animated-link"
import { StaticQuery, graphql } from "gatsby"

export const List = tw.ul`flex font-mont flex-col font-bold items-start justify-start`

export const Link = tw(AnimatedLink)`capitalize`

export const Heading = styled.p(() => [
  tw`relative flex flex-col w-full pb-8 mb-8 font-bold uppercase text-marrone-primary font-mont`,
  css`
    &:after {
      ${tw`absolute bottom-0 left-0 flex w-full bg-marrone-primary`}
      height: 1px;
      content: "";
    }
  `,
])

export const Item = styled.li(({ highContrast }) => [
  tw`flex items-center justify-center text-white`,
  highContrast && tw`text-black`,
])

const MenuList = ({
  items,
  title,
  className,
  children,
  highContrast,
  categories,
  blog,
}) => {
  const listItems = items.map(({ link }, index) => (
    <Item key={index} highContrast={highContrast}>
      <Link to={link.url} target={link.target}>
        {link.title}
      </Link>
    </Item>
  ))
  const listCategories = categories.map((cat, index) => (
    <Item key={index} highContrast={highContrast}>
      <Link to={cat.uri}>{cat.name}</Link>
    </Item>
  ))
  return (
    <List className={className}>
      <Heading>{title}</Heading>
      {blog ? listCategories : listItems}
      {children && <Item>{children}</Item>}
    </List>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWpCategory(filter: { slug: { ne: "uncategorized" } }) {
          nodes {
            name
            uri
          }
        }
      }
    `}
    render={data => (
      <MenuList {...props} categories={data.allWpCategory.nodes} />
    )}
  />
)
