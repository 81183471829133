import React from "react"
import Content from "./content"
import AnimatedHeading from "./animated-heading"
import tw, { styled, css } from "twin.macro"

const List = styled.ul(() => [
  tw`font-mont -my-16 relative`,
  css`
    &:before {
      ${tw`absolute top-0 left-0 bg-marrone-primary z-50`}
      width: 1px;
      height: 100%;
      content: "";
    }
  `,
])

const Item = styled.li(() => [
  tw`flex flex-col items-start justify-start pl-16 my-16 relative`,
  css`
    &:before {
      ${tw`absolute flex left-0 bg-marrone-primary`}
      border-radius: 50%;
      top: 1rem;
      width: 10px;
      height: 10px;
      transform: translateX(-50%);
      content: "";
    }
  `,
])

const BulletedList = ({ items, index }) => {
  const listItems = items.map(item => (
    <Item key={item.title}>
      <AnimatedHeading triggerOnce size="2.5rem">
        {item.title}
      </AnimatedHeading>
      <Content>{item.body}</Content>
    </Item>
  ))
  return <List>{listItems}</List>
}

export default BulletedList
