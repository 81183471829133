import React, { useRef } from "react"
import Img from "gatsby-image"
import tw, { styled, css } from "twin.macro"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { chunk } from "lodash"

const CHUNK_SIZE = 6

const Slide = styled.div(() => [
  tw`flex flex-row flex-wrap w-full -my-8`,
  css`
    .gatsby-image-wrapper {
      width: 200px;

      @media (max-width: 1024px) {
        width: 150px;
        max-width: 100%;
      }

      @media (max-width: 860px) {
        width: 200px;
        max-width: 100%;
      }
    }
    @media (max-width: 860px) {
      ${tw`flex-col items-center`};
    }
  `,
])

const Logo = styled.div(() => [
  tw`flex items-center justify-center py-8`,
  css`
    width: ${Math.floor(100 / (CHUNK_SIZE / 2))}%;

    @media (max-width: 860px) {
      ${tw`w-full`};
      max-width: 350px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6) {
      border-left: 2px solid #dedede;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: 2px solid #dedede;
    }

    @media (max-width: 860px) {
      ${tw`py-12`}
      &:not(:last-child) {
        border-bottom: 2px solid #dedede;
      }
      border-left: 0px !important;
    }
  `,
])

const AwardSlider = ({ awards }) => {
  // Split awards into arrays of CHUNK_SIZE.
  // e.g: CHUNK_SIZE = 6, awards = [a,b,c,d,e,f,g,h,i,j,k,l]
  // chunkedAwards = [[a,b,c,d,e,f],[g,h,i,j,k,l]]
  const chunkedAwards = chunk(awards, CHUNK_SIZE)
  const sliderRef = useRef()
  const listAwards = chunkedAwards.map((single, index) => (
    <div key={index}>
      <Slide>
        {single.map(({ logo }, index) => (
          <Logo key={index}>
            <Img
              fluid={logo.localFile.childImageSharp.fluid}
              alt={logo.altText}
            />
          </Logo>
        ))}
      </Slide>
    </div>
  ))
  // If there is more than one slide, add a slider.
  if (listAwards.length > 1) {
    const settings = {
      slidesPerView: 1,
      infinite: true,
      speed: 500,
      autoplaySpeed: 8000,
      autoplay: true,
      arrows: false,
      ref: sliderRef,
    }
    return (
      <>
        {/* <Chevron
          dir="left"
          className="awards-button-prev"
          onClick={() => {
            sliderRef.current.slickPrev()
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Chevron> */}
        <Slider {...settings}>{listAwards}</Slider>
        {/* <Chevron
          dir="right"
          className="awards-button-next"
          onClick={() => {
            sliderRef.current.slickNext()
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Chevron> */}
      </>
    )
  }
  // Otherwise, just include the one slide without a slider.
  else {
    return listAwards
  }
}

export default AwardSlider
