import React from "react"
import "../../styles/awards.css"
import ReviewList from "../modules/review-list"
import AwardSlider from "../modules/award-slider"
import { graphql, StaticQuery } from "gatsby"

const Awards = () => (
  <StaticQuery
    query={graphql`
      query {
        allWp {
          nodes {
            awards {
              aw {
                awTitle
                awAwards {
                  logo {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 200) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
                awReviews {
                  author
                  body
                  excerpt
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.allWp.nodes[0].awards.aw
      return (
        <section className="aw">
          <div className="aw__left">
            <h2
              className="aw__left--title"
              dangerouslySetInnerHTML={{ __html: fields.awTitle }}
            ></h2>
            <ReviewList reviews={fields.awReviews} />
          </div>
          <div className="aw__right">
            <AwardSlider awards={fields.awAwards} />
          </div>
        </section>
      )
    }}
  />
)

export default Awards
