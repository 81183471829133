import React from "react"
import tw, { css } from "twin.macro"
import ModalContext from "./modal-context"

const styles = [
  tw`uppercase whitespace-nowrap transition-all duration-500 ease-in-out relative inline-block font-bold font-mont py-2 text-sm`,
  css`
    &:after {
      ${tw`transition-all duration-500 ease-in-out absolute left-0 bottom-0 w-full flex bg-black`};
      content: "";
      height: 2px;
    }

    &:hover {
      ${tw`bg-marrone-primary text-white px-4 cursor-pointer`}

      &:after {
        ${tw`opacity-0`}
      }
    }
  `,
]

const UnderlinedLink = ({ children, link, newTab, embed }) => {
  if (!embed)
    if (newTab)
      return (
        <a
          css={styles}
          className="underlined-link"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )
    else
      return (
        <a css={styles} className="underlined-link" href={link}>
          {children}
        </a>
      )
  else
    return (
      <ModalContext.Consumer>
        {modal => (
          <button
            className="underlined-link"
            onClick={() => {
              modal.openModal(embed)
            }}
            css={styles}
          >
            {children}
          </button>
        )}
      </ModalContext.Consumer>
    )
}

export default UnderlinedLink
