import React from "react"
import tw, { styled, css } from "twin.macro"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const units = ["rem", "px", "em", "%"]

const Wrapper = styled.div(({ height }) => [
  tw`relative w-full`,
  css`
    height: 800px;
  `,
  new RegExp(`^[0-9]+(${units.join("|")})$`).test(height) &&
    css`
      height: ${height};
    `,
  css`
    @media (max-width: 1024px) {
      height: inherit;
    }
  `,
])

const BgWrapper = styled.div(() => [tw`w-full h-full absolute top-0 left-0`])

const Foreground = styled.div(() => [
  tw`absolute flex items-end justify-end bottom-0 z-40 -mb-32`,
  css`
    right: 35%;
    transform: translateX(50%);

    @media (max-width: 1440px) {
      right: 25%;
    }

    @media (max-width: 1280px) {
      width: 25%;
      right: 0;
      top: 0;
      transform: none;
    }
  `,
])

const Background = tw(
  Img
)`z-30 w-full h-full object-cover absolute top-0 left-0`

const Content = styled.div(() => [
  tw`absolute pl-32 z-50 left-0 text-white`,
  css`
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1440px) {
      ${tw`px-8`}
    }

    @media (max-width: 1024px) {
      ${tw`relative py-32`}
      transform: none;
    }

    @media (max-width: 860px) {
      ${tw`px-4`}
    }

    @media (max-width: 640px) {
      ${tw`py-16`}
    }
  `,
])

export default class ParallaxBanner extends React.Component {
  state = {
    width: 0,
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)
    this.setState({ width: window.innerWidth })
  }

  componentWillUnmount() {
    // Make sure the event listener is removed if the component
    // is no longer mounted.
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    // On resize, update the state width to be the window's
    // inner width.
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { children, height, fg, bg } = this.props
    let disableWidth = this.props
    if (!disableWidth) disableWidth = 1024
    return (
      <Wrapper height={height}>
        <BgWrapper>
          <Background fluid={bg} />
        </BgWrapper>
        <Content>{children}</Content>
        {this.state.width > 1280 && (
          <Foreground>
            <Parallax y={[20, -30]}>
              <Img fixed={fg} />
            </Parallax>
          </Foreground>
        )}
      </Wrapper>
    )
  }
}
