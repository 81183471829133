import React from "react"
import { Link } from "gatsby"
import { internalLink, relativeLink } from "../../../util/regex"
import "../../styles/animated-link.css"

const AnimatedLink = ({ children, to, target }) => {
  if (
    !target &&
    (new RegExp(internalLink).test(to) || new RegExp(relativeLink).test(to))
  ) {
    // If it's an internal link, use Gatsby Link API.
    return (
      <Link
        className="animated-link"
        to={to}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    )
  } else {
    // Otherwise, just use an A tag.
    if (target)
      return (
        <a
          dangerouslySetInnerHTML={{ __html: children }}
          className="animated-link"
          href={to}
          target={target}
          rel="noreferrer"
        />
      )
    else return <a href={to} dangerouslySetInnerHTML={{ __html: children }} />
  }
}

export default AnimatedLink
