import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/front"
import SEO from "../components/seo"
import { ParallaxProvider } from "react-scroll-parallax"
import { ModalProvider } from "../components/modules/modal-context"
import { FormProvider } from "../components/modules/popup-form-context"
import FrontHero from "../components/partials/front-hero"
import { getImage } from "../../util/functions"
import PopupForm from "../components/modules/popup-form"
import AOPS from "../components/partials/aops"
import Awards from "../components/partials/awards"
import CaseResults from "../components/partials/case-results"
import WhyChoose from "../components/partials/why-choose"
import FirmOverview from "../components/partials/firm-overview"

export default ({ data }) => {
  const seo = data.allWpPage.edges[0].node.seo
  const robots = {
    name: `robots`,
    content: `${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`,
  }

  const meta = [robots]

  const addToMeta = (name, content) => {
    if (content) meta.push({ property: name, content: content })
  }

  addToMeta("og:site_name", seo.opengraphSiteName)
  addToMeta(
    "og:image",
    seo.opengraphImage?.localFile?.publicURL
      ? getImage(seo.opengraphImage.localFile.publicURL, true, false)
      : null
  )
  addToMeta("article:publisher", seo.opengraphPublisher)
  addToMeta("article:modified_time", seo.opengraphModifiedTime)
  addToMeta("article:published_time", seo.opengraphPublishedTime)
  addToMeta("og:type", seo.opengraphType)

  return (
    <FormProvider>
      <ModalProvider>
        <ParallaxProvider>
          <Layout highContrast>
            <SEO
              title={seo.title}
              meta={meta}
              description={seo.metaDesc}
              schema={seo.schema?.raw}
            />
            <PopupForm />
            <FrontHero />
            <CaseResults centered />
            <FirmOverview />
            <WhyChoose />
            <Awards />
            <AOPS />
          </Layout>
        </ParallaxProvider>
      </ModalProvider>
    </FormProvider>
  )
}

export const pageQuery = graphql`
  fragment SEO on WpPostTypeSEO {
    metaKeywords
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    title
    breadcrumbs {
      text
      url
    }
    schema {
      raw
    }
  }
  fragment PostSEO on WpPostTypeSEO {
    opengraphAuthor
    opengraphDescription
    opengraphModifiedTime
    opengraphPublishedTime
    opengraphPublisher
    opengraphTitle
    opengraphSiteName
    opengraphType
    opengraphUrl
    opengraphImage {
      localFile {
        publicURL
      }
    }
  }
  query {
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          seo {
            ...SEO
            ...PostSEO
          }
        }
      }
    }
    file(relativePath: { eq: "eagles-footer-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
