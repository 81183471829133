import React from "react"
import "../../styles/aops.css"
import { graphql, StaticQuery } from "gatsby"
import Outline from "../modules/outline"
import MenuList from "../modules/menu-list"
import Button from "../modules/button"

const AOPS = () => (
  <StaticQuery
    query={graphql`
      fragment Link on WpACF_Link {
        title
        url
        target
      }
      query {
        wp {
          areasOfPractice {
            aops {
              aopsTitle
              aopsBg
              aopsButton {
                ...Link
              }
              aopsMenu1 {
                aopsMenu1Title
                aopsMenu1Menu {
                  link {
                    ...Link
                  }
                }
              }
              aopsMenu2 {
                aopsMenu2Title
                aopsMenu2Menu {
                  link {
                    ...Link
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.wp.areasOfPractice.aops
      return (
        <section className="aops">
          <Outline
            color="#252525"
            size="15rem"
            className="aops__bg"
            thickness="1px"
          >
            {fields.aopsBg}
          </Outline>
          <div className="aops__content">
            <p className="aops__content--title">{fields.aopsTitle}</p>
            <MenuList
              title={fields.aopsMenu1.aopsMenu1Title}
              items={fields.aopsMenu1.aopsMenu1Menu}
            >
              <Button
                to={fields.aopsButton.url}
                target={fields.aopsButton.target}
              >
                {fields.aopsButton.title}
              </Button>
            </MenuList>
            <MenuList
              title={fields.aopsMenu2.aopsMenu2Title}
              items={fields.aopsMenu2.aopsMenu2Menu}
            ></MenuList>
          </div>
        </section>
      )
    }}
  />
)

export default AOPS
