import React from "react"
import "../../styles/firm-overview.css"
import Subtitle from "../modules/subtitle"
import UnderlinedLink from "../modules/underlined-link"
import Heading from "../modules/heading"
import Img from "gatsby-image"
import ModalContext from "../modules/modal-context"
import { graphql, StaticQuery } from "gatsby"
import tw from "twin.macro"

const FirmOverview = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "play-btn.png" }) {
          childImageSharp {
            fluid(maxWidth: 170, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        allWp {
          nodes {
            firmOverview {
              fo {
                foPretext
                foBody
                foHeading
                foButton {
                  title
                  url
                }
                foThumbnail {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                foVideoEmbed
              }
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.allWp.nodes[0].firmOverview.fo
      return (
        <section className="fo">
          <ModalContext.Consumer>
            {modal => (
              <div
                tabIndex={0}
                role="button"
                className="fo__left"
                onClick={() => modal.openModal(fields.foVideoEmbed)}
                onKeyDown={() => modal.openModal(fields.foVideoEmbed)}
              >
                <Img
                  fluid={fields.foThumbnail.localFile.childImageSharp.fluid}
                  alt={fields.foThumbnail.altText}
                  className="fo__left--thumb"
                />
                <Img
                  className="fo__left--play"
                  fluid={data.file.childImageSharp.fluid}
                  alt="Play button"
                />
              </div>
            )}
          </ModalContext.Consumer>
          <div className="fo__right">
            <Subtitle css={[tw`mb-2`]}>{fields.foPretext}</Subtitle>
            <Heading h1>{fields.foHeading}</Heading>
            <article
              className="fo__right--content"
              dangerouslySetInnerHTML={{ __html: fields.foBody }}
            ></article>
            <UnderlinedLink link={fields.foButton.url}>
              {fields.foButton.title}
            </UnderlinedLink>
          </div>
        </section>
      )
    }}
  />
)

export default FirmOverview
