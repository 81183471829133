import React from "react"
import "../../styles/why-choose.css"
import ParallaxBanner from "../modules/parallax-banner"
import BulletedList from "../modules/bulleted-list"
import Heading from "../modules/heading"
import { graphql, StaticQuery } from "gatsby"

const WhyChoose = () => (
  <StaticQuery
    query={graphql`
      query {
        allWp {
          nodes {
            whyChoose {
              wc {
                wcBannerBg {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                wcBannerFg {
                  altText
                  localFile {
                    childImageSharp {
                      fixed(width: 600) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                wcBannerText
                wcReasons {
                  body
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.allWp.nodes[0].whyChoose.wc
      return (
        <section className="wc">
          <ParallaxBanner
            bg={fields.wcBannerBg.localFile.childImageSharp.fluid}
            fg={fields.wcBannerFg.localFile.childImageSharp.fixed}
          >
            <Heading size="4rem">{fields.wcBannerText}</Heading>
          </ParallaxBanner>
          <div className="wc__reasons">
            <BulletedList items={fields.wcReasons}></BulletedList>
          </div>
        </section>
      )
    }}
  />
)

export default WhyChoose
