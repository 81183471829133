import React from "react"
import tw from "twin.macro"
import "../../styles/content.css"

const Content = ({ children, inject, sitemap }) => {
  // If inject is undefined or otherwise not false,
  // we want to inject the HTML. This effectively makes
  // the inject prop optional, injecting by default.
  // Otherwise, we pass the children prop along as children.
  if (inject !== false)
    return (
      <article
        className={sitemap ? "content sitemap" : "content"}
        dangerouslySetInnerHTML={{ __html: children }}
      ></article>
    )
  else return <article className="content">{children}</article>
}

export default tw(Content)`text-base leading-loose`
