import React from "react"
// import PropTypes from "prop-types"
import "../../styles/front-hero.css"
import Heading from "../modules/heading"
import Outline from "../modules/outline"
import Subtitle from "../modules/subtitle"
import { Link } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { PopupButton } from "../modules/popup-form"
import { getImage } from "../../../util/functions"
import { graphql, StaticQuery } from "gatsby"
import DrawnText from "../modules/drawn-text"
import Media from "react-media"

const SHOW_HITBOXES = false

const MobileImage = styled.img`
  ${tw`hidden`}
  @media (max-width: 1024px) {
    ${tw`flex`}
  }
`

const HitboxElement = styled.div(({ visible }) => [
  tw`absolute bottom-0 right-0 overflow-hidden cursor-pointer`,
  visible &&
    css`
      ${tw`flex items-center justify-center bg-green-600 bg-opacity-25`}

      &:after {
        ${tw`absolute font-bold`}
        top: 2rem;
        content: "Hover Hitbox";
      }
    `,
  css`
    z-index: 10;
    width: 51.5%;
    height: 90%;

    a {
      ${tw`flex flex-col items-center justify-center w-full h-full font-bold transition-all duration-500 ease-in-out opacity-0`}
      transform: translateY(-2rem);
      padding-top: 30%;
      &:before {
        ${tw`bg-marrone-primary mb-4`}
        height: 60px;
        width: 2px;
        content: "";
      }
    }

    &:hover {
      a {
        opacity: 100%;
        transform: none;
      }
    }

    @media (max-width: 1024px) {
      ${tw`hidden`}
    }
  `,
])

class Hitbox extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    this.ref.current.addEventListener("mouseenter", () => {
      document.querySelector(".fh__overlay").classList.add("hover")
    })
    this.ref.current.addEventListener("mouseout", () => {
      document.querySelector(".fh__overlay").classList.remove("hover")
    })
  }

  render() {
    return (
      <HitboxElement ref={this.ref} visible={SHOW_HITBOXES}>
        <Link to="/attorneys/">Meet Our Attorneys</Link>
      </HitboxElement>
    )
  }
}

class FrontHero extends React.Component {
  // state = {
  //   width: 1,
  // }

  // constructor(props) {
  //   super(props)
  //   this.setState({ width: window.innerWidth })
  // }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wp {
              frontHero {
                fh {
                  appearances {
                    logo {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                  button {
                    title
                    url
                  }
                  heading
                  subtitle
                  fhOverlay {
                    localFile {
                      publicURL
                    }
                  }
                  portraits {
                    localFile {
                      publicURL
                    }
                  }
                  mobileBackground {
                    localFile {
                      publicURL
                    }
                  }
                  subtitle
                  bgText
                }
              }
            }
          }
        `}
        render={data => {
          const fields = data.wp.frontHero.fh
          const listAppearances = fields.appearances.map(
            (appearance, index) => (
              <li key={index} className="appearance">
                <Link to="/media-library/">
                  <img
                    src={getImage(appearance.logo.localFile.publicURL, true)}
                    alt={appearance.logo.altText}
                  />
                </Link>
              </li>
            )
          )
          return (
            <section className="fh">
              <Media
                queries={{
                  mobile: "(max-width: 1024px)",
                }}
                defaultMatches={{
                  mobile: true,
                }}
              >
                {matches =>
                  matches.mobile ? (
                    <MobileImage
                      className="fh__mobile"
                      src={getImage(
                        fields.mobileBackground.localFile.publicURL,
                        true
                      )}
                      alt="Mobile Background"
                    />
                  ) : (
                    <img
                      className="fh__overlay"
                      src={getImage(fields.fhOverlay.localFile.publicURL, true)}
                      alt="Overlay"
                    />
                  )
                }
              </Media>
              <div className="fh__content">
                <Heading>{fields.heading}</Heading>
                <Subtitle tw="-mt-4">{fields.subtitle}</Subtitle>
                <PopupButton>{fields.button.title}</PopupButton>
                <ul className="fh__content--appearances">{listAppearances}</ul>
              </div>
              <div className="fh__trans">
                {/* <Outline color="#252525" size="15rem">
                  {fields.bgText}
                </Outline> */}
                <DrawnText color="#252525" name="front-hero" />
              </div>
              <Hitbox />
            </section>
          )
        }}
      />
    )
  }
}

export default FrontHero
