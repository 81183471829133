import React from "react"
import tw, { styled, css } from "twin.macro"

const SubtitleComp = styled.p(({ color }) => [
  tw`text-2xl mb-0 text-marrone-primary font-bold font-mont mt-2`,
  color &&
    css`
      color: ${color};
    `,
  css`
    @media (max-width: 1280px) {
      ${tw`text-xl`}
    }
  `,
])

const Subtitle = ({ children, color, className }) => (
  <SubtitleComp
    className={className ? className : "subtitle"}
    color={color}
    dangerouslySetInnerHTML={{ __html: children }}
  ></SubtitleComp>
)

export default Subtitle
