import React from "react"
import tw, { styled, css } from "twin.macro"
import { useInView } from "react-intersection-observer"
import Heading from "./heading"

const Block = styled.div(() => [
  tw`transition-all ease-in-out bg-marrone-primary flex relative mb-6 -mt-2`,
  css`
    transition-duration: 1s;
    height: 10px;
    width: 0;

    &.active {
      width: 80px;
    }
  `,
])

const AnimationContainer = styled.div(() => [
  tw`mb-4 relative overflow-hidden`,
  css`
    &:after {
      ${tw`transition-all ease-in-out w-full h-full absolute top-0 left-0 bg-marrone-primary`}
      transition-duration: 1.5s;
      content: "";
    }
    &.active {
      &:after {
        transform: translateX(105%);
      }
    }
  `,
])

export default props => {
  const { triggerOnce } = props
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: triggerOnce,
  })
  return (
    <>
      <AnimationContainer className={inView && "active"}>
        <Heading {...props} />
      </AnimationContainer>
      <Block ref={ref} className={inView && "active"} />
    </>
  )
}
